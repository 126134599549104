// eslint-disable-next-line no-redeclare,no-unused-vars
function buildConfirmPopup(question, callback, no) {
  var buttons = {};
  buttons['Ja'] = function () {
    callback(true);
    popup.close();
  };
  if (no)
    buttons['Nein'] = function () {
      callback('');
      popup.close();
    };
  var popup = buildPopup({
    size: 'xs',
    ident: 'info',
    title: 'Achtung',
    content: question,
    buttons: buttons,
    onTop: true,
    bodyCard: true,
  });
}
